import React, { useEffect } from 'react'
import { SignedIn, SignedOut, useUser } from '@clerk/clerk-react'
import SignUpModal from '../components/sign-up-modal'
import Layout from '../components/molecules/layout'
import Seo from '../components/molecules/seo'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const Dashboard = () => {
  const mixpanel = useMixpanel()
  const { user } = useUser({ withAssertions: true })

  useEffect(() => {
    const params = new URLSearchParams(`${window.location.search}`)
    if (params.get('signedUp') && user && user.id) {
      mixpanel.identify(user.id)
      mixpanel.track('loadedDashboardAfterSignup')
    }
  }, [mixpanel, user])

  const title = 'dashboard'

  return (
    <Layout>
      <Seo title={title} />
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="page-title">{title}</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-full content">
              <main>
                <SignedOut>
                  <h2>sign up or login to get started</h2>
                  <SignUpModal></SignUpModal>
                </SignedOut>
                <SignedIn>
                  <a
                    href="https://dashboard.phonetonote.com/"
                    className="button is-primary"
                  >
                    go to dashboard
                  </a>
                </SignedIn>
              </main>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Dashboard
